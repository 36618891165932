.contentCustom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 15px;
  width: 600px;
  height: 100%;
  overflow-y: auto;
}

.overlayCustom {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .contentCustom {
    width: 450px;
  }
}

@media screen and (max-width: 520px) {
  .contentCustom {
    width: 380px;
  }
}

@media screen and (max-width: 475px) {
  .contentCustom {
    top: 0;
    left: 0;
    transform: unset;
    border-radius: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .overlayCustom {
    left: 0;
    transform: unset;
  }
}

.contentCustom::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.contentCustom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: orange !important;
}

/* Handle */
.contentCustom::-webkit-scrollbar-thumb {
  background: #4a8;
  border-radius: 10px;
}

/* Handle on hover */
.contentCustom::-webkit-scrollbar-thumb:hover {
  background: #4f478d;
}

.contentCustom::-webkit-scrollbar {
  width: 6px !important;
  background: #f8f8f8 !important;
  border-radius: 50px;
}

.contentCustom::-webkit-scrollbar-track-piece {
  background-color: #dcdceb;
}